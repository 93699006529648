"use client";
import "./page.css";
import Head from "next/head";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "@/theme";
import { IntlProvider } from "react-intl";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "@/config/config";
import { SnackbarProvider } from "@/context/SnackbarContext";
import { CountryProvider } from "@/context/CountryContext";
import { getAuthToken } from "@/utils/authToken";
import { ApolloWrapper } from "./ApolloWrapper";
import { memo, ReactNode, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { GoogleAnalytics } from "@next/third-parties/google";
import CookieConsent from "@/components/Commons/CookieConsent/CookieConsent";
import Cookies from "js-cookie";
import { isJSON } from "@/utils/stringMethods";
import { fr } from "date-fns/locale/fr";
import { useParams } from "next/navigation";
import { CompagnyContextProvider } from "@/context/CompagnyContext";
import { Box } from "@mui/material";
import dynamic from "next/dynamic";
import GoogleOneTapLogin from "@/components/Commons/GoogleOneTapLogin/GoogleOneTapLogin";

const HeaderCompagny = dynamic(
  () => import("@/components/Commons/HeaderCompagny/HeaderCompagny")
);
const FooterCompagny = dynamic(
  () => import("@/components/Commons/FooterCompagny/FooterCompagny")
);
const Header = dynamic(() => import("@/components/Commons/Header/Header"));
const Footer = dynamic(() => import("@/components/Commons/Footer/Footer"));
const ChatBot = dynamic(() => import("@/components/Commons/ChatBot/ChatBot"));
const WhatsappFlottingButton = dynamic(
  () =>
    import("@/components/Commons/WhatsappFlottingButton/WhatsappFlottingButton")
);

function Layout({ children }: { children: ReactNode }) {
  const token = getAuthToken();
  const { compagny } = useParams();
  const [hasConsented, setHasConsented] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");

    if (consent && isJSON(consent)) {
      const parsedConsent = JSON.parse(consent);
      setPreferences(parsedConsent);
      setHasConsented(parsedConsent.analytics); // Check if analytics consent was given
    }
  }, []);

  const baseStyle: any = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  };

  return (
    <html lang="fr">
      <Head>
        <meta name="apple-itunes-app" content="app-id=6477760847" />
        <meta
          name="google-site-verification"
          content="yyBmI1YELTsFHYdIT4tFqGqmb0aLbm7Xg2PA1P4tSO4"
        />
        <title>LeHubs</title>
      </Head>
      <body>
        {hasConsented && preferences.analytics && (
          <GoogleAnalytics gaId="G-PXFKX0MQT2" />
        )}
        <AppRouterCacheProvider options={{ enableCssLayer: true }}>
          <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
            <ApolloWrapper token={token}>
              <CssBaseline />
              <CountryProvider>
                <SnackbarProvider>
                  <GoogleOAuthProvider clientId={config.googleClient}>
                    <IntlProvider
                      defaultLocale="fr"
                      messages={{ hello: "hello" }}
                      locale={"fr"}
                    >
                      <ThemeProvider theme={theme}>
                        {!Boolean(token.length) && <GoogleOneTapLogin />}
                        {compagny?.length ? (
                          <CompagnyContextProvider
                            compagnySlug={compagny?.toString() || ""}
                          >
                            <HeaderCompagny />
                            <main
                              style={{
                                ...baseStyle,
                                backgroundColor: "white",
                              }}
                            >
                              {children}
                            </main>
                            <FooterCompagny />
                          </CompagnyContextProvider>
                        ) : (
                          <Box
                            sx={{
                              ...baseStyle,
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            <Header />
                            {children}
                            <Footer />
                          </Box>
                        )}
                        <CookieConsent />
                        <WhatsappFlottingButton />
                        <ChatBot />
                      </ThemeProvider>
                    </IntlProvider>
                  </GoogleOAuthProvider>
                </SnackbarProvider>
              </CountryProvider>
            </ApolloWrapper>
          </LocalizationProvider>
        </AppRouterCacheProvider>
      </body>
    </html>
  );
}

export default memo(Layout);
