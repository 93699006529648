"use client";
import { ApolloLink, HttpLink, split } from "@apollo/client";
import {
  ApolloNextAppProvider,
  InMemoryCache,
  ApolloClient,
} from "@apollo/experimental-nextjs-app-support";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { setVerbosity } from "ts-invariant";
import { createClient } from "graphql-ws";
import config from "@/config/config";
import { getMainDefinition } from "@apollo/client/utilities";
import { PropsWithChildren } from "react";

if (process.env.NODE_ENV === "development") {
  setVerbosity("debug");
  loadDevMessages();
  loadErrorMessages();
}

export function ApolloWrapper({
  children,
  token,
}: PropsWithChildren<{
  token: string;
}>) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );

  function makeClient() {
    const httpLink = new HttpLink({
      uri: config.GRAPHQL_URL,
      fetchOptions: { cache: "no-store" },
    });

    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }));
      return forward(operation);
    });

    // WebSocket link for subscriptions
    const wsLink =
      typeof window !== "undefined"
        ? new GraphQLWsLink(
            createClient({
              url: config.GRAPHQL_WS_URL,
              connectionParams: { authToken: token },
              retryAttempts: Infinity,
              shouldRetry: () => true,
              keepAlive: 10000,
            })
          )
        : null;

    // Use split for proper link usage
    const link = wsLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          authLink.concat(httpLink)
        )
      : authLink.concat(httpLink);

    return new ApolloClient({
      cache: new InMemoryCache(),
      link,
    });
  }
}
